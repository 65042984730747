import { pageRoutes } from "constant/routes";
import { imageBasePath } from "lib/utils/imageUtils";

export const footerLinks = {
  resources: [
    {
      text: "Education Loan Eligibility",
      link: "/education-loan-eligibility",
      clickId: "15",
    },
    {
      text: "Education Loan Interest",
      link: "/education-loan-interest-rate",
      clickId: "16",
    },
    {
      text: "Education Loan Calculator",
      link: "/education-loan-calculator",
      clickId: "17",
    },
    {
      text: "Education Loan Without Collateral",
      link: "/education-loan-without-collateral",
      clickId: "18",
    },
    {
      text: "Education Loan Documents",
      link: "/education-loan-documents",
      clickId: "19",
    },
    {
      text: "Education Loan Tax Benefits",
      link: "/education-loan-tax-benefits",
      clickId: "20",
    },
    {
      text: "How to apply for Education Loan",
      link: "/education-loan-application-process",
      clickId: "21",
    },
    {
      text: "LeapScholar",
      link: "https://leapscholar.com/",
      event: "LeapScholar",
    },

    {
      text: "LeapScholar Blog",
      link: "https://leapscholar.com/blog/",
      event: "LeapScholar Blog",
    },
  ],
  company: [
    {
      text: "Home",
      link: "/",
      clickId: "7",
    },
    {
      text: "About Us",
      link: "/about",
      clickId: "8",
    },
    {
      text: "Leap in the news!",
      link: "https://press.leapscholar.com/",
      clickId: "9",
    },
  ],
  products: [
    {
      text: "Leap Scholar",
      link: "https://leapscholar.com/",
      event: "LeapScholar",
    },
    {
      text: "LeapAdvantage",
      link: "https://advantage.leapscholar.com",
      event: "LeapAdvantage",
    },
    {
      text: "IELTS by LeapScholar",
      link: "https://leapscholar.com/ielts-preparation-online",
      event: "IELTS by LeapScholar",
    },
  ],
  socialMedia: [
    {
      icon: `${imageBasePath}/assets/icons/twitter-icon.svg`,
      link: "https://twitter.com/leapfinancein",
      contentName: "Twitter",
    },
    {
      icon: `${imageBasePath}/assets/icons/linkedin-icon.svg`,
      link: "https://in.linkedin.com/company/leap-global-education",
      contentName: "Linkedin",
    },
    {
      icon: `${imageBasePath}/assets/icons/instagram-icon.svg`,
      link: "https://www.instagram.com/leap_finance/",
      contentName: "Instagram",
    },
    {
      icon: `${imageBasePath}/assets/icons/facebook-icon.svg`,
      link: "https://www.facebook.com/leapfinanceofficial/",
      contentName: "Facebook",
    },
    {
      icon: `${imageBasePath}/assets/icons/youtube-icon.svg`,
      link: "https://www.youtube.com/channel/UCIUFBy0KWMBxRHFiPMtXOBQ",
      contentName: "Youtube",
    },
  ],
  partners: [
    {
      link: "https://yocket.com",
      contentName: "Yocket study abroad",
    },
    {
      link: "https://geebeeworld.com",
      contentName: "GEEBEE",
    },
  ],
  testimonialLink: pageRoutes.INSCHOOL_REVIEWS,
  careersLink: "https://careers.leapfinance.com/",
  legal: [
    {
      text: "Privacy Policy",
      link: "https://leapfinance.com/privacy",
      clickId: "13",
    },
    {
      text: "Terms and Conditions",
      link: "https://leapfinance.com/terms",
      clickId: "14",
    },
    {
      text: "SOFR Details",
      link: "/sofr",
      clickId: "49",
    },
  ],
};

export const minLoanAmountByCountryNames = [
  "The minimum loan amount in NJ is $50,000.",
  "The minimum loan amount in IL is $40,000.",
  "The minimum loan amount in FL is $25,000.",
  "The minimum loan amount in NC is $15,000.",
  "The minimum loan amount in AZ is $10,000.",
];

export const footerAddresses = [
  {
    heading: "HQ- Bangalore  (India)",
    address:
      "163/A, 9th Main Rd, Sector 6, HSR Layout, Bengaluru, Karnataka 560102",
  },
  {
    heading: "Singapore  (International)",
    address: "11 Collyer Quay #17-00 The Arcade, 049317, Singapore",
  },
  {
    heading: "UAE  (International)",
    address: (
      <span>
        Office 1106, Arenco Tower, Sheikh Zayed Road, <br /> Media City Dubai -
        3087, UAE <br /> Contact Number: +971 501501220
      </span>
    ),
  },
  {
    heading: "USA",
    address: "850 New Burton Road, Suite 201 Dover, Delaware 19904 USA",
  },
];
